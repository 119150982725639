import { DecimalPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ApplicationModule, NgModule } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardAreaModule } from './dashboard-area/dashboard-area.module';
import { AlertService } from './dashboard-area/shared/alert.service';
import { ExportPdfButtonService } from './dashboard-area/shared/export-pdf-button/export-pdf-button.service';
import { WorkSessionService } from './dashboard-area/shared/work-session.service';
import { ThingSimService } from './dashboard-area/thing/thing-sim/thing-sim.service';
import { ActionContextService } from './service/action-context.service';
import { AddButtonContextService } from './service/add-button-context.service';
import { AlertDefinitionService } from './service/alert-definition.service';
import { ApiKeyService } from './service/api-key.service';
import { AppService } from './service/app.service';
import { authInterceptorProviders } from './service/auth.interceptor';
import { AuthenticationService } from './service/authentication.service';
import { BreadcrumbService } from './service/breadcrumb.service';
import { CommandService } from './service/command.service';
import { ContextService } from './service/context.service';
import { ControlBarService } from './service/control-bar.service';
import { CountryService } from './service/country.service';
import { CustomLabelService } from './service/custom-label.service';
import { CustomPropertyService } from './service/custom-property.service';
import { CustomerTreeService } from './service/customer-tree.service';
import { DataExportConfigurationService } from './service/data-export-configuration.service';
import { DataService } from './service/data.service';
import { DateRangeService } from './service/date-range.service';
import { DefaultPropertySettingsService } from './service/default-property-settings.service';
import { DownloadService } from './service/download.service';
import { ExpandableWidgetDialogService } from './service/expandable-widget-dialog.service';
import { ExportContextService } from './service/export-context.service';
import { ExternalApiClientService } from './service/external-api-client.service';
import { ExternalAppUtilsService } from './service/external-app-utils.service';
import { ExternalCatalogService } from './service/external-catalog.service';
import { ExternalComponentUtilsService } from './service/external-component-utils.service';
import { FieldService } from './service/field.service';
import { FilterService } from './service/filter.service';
import { FlatTreeService } from './service/flat-tree.service';
import { FormCheckerService } from './service/form-checker.service';
import { HttpService } from './service/http.service';
import { IncrementService } from './service/increment.service';
import { InternalHttpService } from './service/internal-http.service';
import { LocalStorageService } from './service/local-storage.service';
import { LocalizationService } from './service/localization.service';
import { LogoutService } from './service/logout.service';
import { MetricService } from './service/metric.service';
import { NavigationService } from './service/navigation.service';
import { NetworkDataService } from './service/network-data.service';
import { NetworkMetricService } from './service/network-metric.service';
import { ParameterService } from './service/parameter.service';
import { PatternValidationService } from './service/patternValidation.service';
import { PermissionService } from './service/permission.service';
import { ProductModelCategoryService } from './service/product-model-category.service';
import { ProductModelPartService } from './service/product-model-part.service';
import { ProductModelSparePartDefinitionReferenceService } from './service/product-model-spare-part-definition-reference.service';
import { ProductModelService } from './service/product-model.service';
import { PropertyService } from './service/property.service';
import { QuickHistoryDialogService } from './service/quick-history-dialog.service';
import { RefresherWidgetService } from './service/refresher-widget.service';
import { ServiceLevelService } from './service/service-level.service';
import { SocketService } from './service/socket.service';
import { StatisticService } from './service/statistic.service';
import { StoreCartService } from './service/store-cart.service';
import { StoreOrderProviderService } from './service/store-order-provider.service';
import { StoreOrderService } from './service/store-order.service';
import { SubscriptionService } from './service/subscription.service';
import { ThingContextService } from './service/thing-context.service';
import { ThingDefinitionService } from './service/thing-definition.service';
import { ThingService } from './service/thing.service';
import { TimezoneService } from './service/timezone.service';
import { TitleService } from './service/title.service';
import { TopBarExportContextService } from './service/top-bar-export-context.service';
import { TreeNodeService } from './service/tree-node.service';
import { TreeService } from './service/tree.service';
import { UiService } from './service/ui.service';
import { UserActionStatusService } from './service/user-action-status.service';
import { UserCustomerService } from './service/user-customer.service';
import { UserLocationService } from './service/user-location.service';
import { UserPartnerService } from './service/user-partner.service';
import { UserThingService } from './service/user-thing.service';
import { UserTypeService } from './service/user-type.service';
import { UserValuesService } from './service/user-values.service';
import { ViewService } from './service/view.service';
import { WearStatusService } from './service/wear-status.service';
import { WidgetRegistrationService } from './service/widget-registration.service';
import { WorkSessionDefinitionService } from './service/work-session-definition.service';
import { AlertSeverityPipe, BulkUpdateLocalizationPipe, CapitalizePipe, DatetimeFormatterPipe, DefaultCloudStatusPipe, DefaultConnectionStatusPipe, DefaultServiceLevelPipe, FormErrorPipe, LoaderPipe, LocalizationPipe, TagLocalizationPipe } from './shared/pipe';
import { ByteFormatterPipe } from './shared/pipe/byte-formatter.pipe';
import { DefaultEventSeverityPipe } from './shared/pipe/default-event-severity.pipe';
import { DefaultSimSessionStatusPipe } from './shared/pipe/default-sim-session-status.pipe';
import { EventSeverityPipe } from './shared/pipe/event-severity.pipe';
import { UnderscoreRemoverPipe } from './shared/pipe/underscore-remover.pipe';
import { TagService } from './shared/tags/tag.service';
import { UserService } from './shared/users/user.service';
import { StyleLoaderComponent } from './style-loader/style-loader.component';
import { FormUtility, HttpUtility, ObjectUtility, Sorting } from './utility';
import { SparePartDefinitionService } from './widget/shared/spare-part-definition.service';

library.add(fas, far, fab);

@NgModule({
    imports: [
        ApplicationModule,
        BrowserAnimationsModule,
        FontAwesomeModule,
        HttpClientModule,
        DashboardAreaModule.forRoot(),
        AppRoutingModule
    ],
    declarations: [
        StyleLoaderComponent,
        AppComponent
    ],
    exports: [],
    providers: [
        authInterceptorProviders,
        HttpUtility,
        ObjectUtility,
        Title,
        FormUtility,
        Sorting,
        AppService,
        AuthenticationService,
        BreadcrumbService,
        ContextService,
        CustomLabelService,
        CustomPropertyService,
        CustomerTreeService,
        DataService,
        FieldService,
        FormCheckerService,
        HttpService,
        InternalHttpService,
        LocalStorageService,
        LocalizationService,
        MetricService,
        NavigationService,
        SocketService,
        ThingContextService,
        ThingService,
        CountryService,
        TimezoneService,
        TitleService,
        TreeService,
        UserTypeService,
        ViewService,
        DownloadService,
        RefresherWidgetService,
        StatisticService,
        PropertyService,
        ExternalAppUtilsService,
        ExternalApiClientService,
        ExternalComponentUtilsService,
        LocalizationPipe,
        LoaderPipe,
        DefaultServiceLevelPipe,
        BulkUpdateLocalizationPipe,
        FormErrorPipe,
        TagLocalizationPipe,
        UnderscoreRemoverPipe,
        SubscriptionService,
        CommandService,
        ParameterService,
        NetworkMetricService,
        NetworkDataService,
        ActionContextService,
        UserCustomerService,
        UserLocationService,
        UserPartnerService,
        UserThingService,
        WidgetRegistrationService,
        UiService,
        DateRangeService,
        UserValuesService,
        IncrementService,
        ThingSimService,
        DefaultPropertySettingsService,
        PatternValidationService,
        DecimalPipe,
        ByteFormatterPipe,
        DefaultSimSessionStatusPipe,
        TagService,
        UserService,
        DatetimeFormatterPipe,
        AlertSeverityPipe,
        EventSeverityPipe,
        DefaultConnectionStatusPipe,
        DefaultCloudStatusPipe,
        DefaultEventSeverityPipe,
        ControlBarService,
        AlertService,
        TopBarExportContextService,
        ExportContextService,
        QuickHistoryDialogService,
        LogoutService,
        ExportPdfButtonService,
        WorkSessionService,
        FilterService,
        ApiKeyService,
        ThingDefinitionService,
        AlertDefinitionService,
        ServiceLevelService,
        WorkSessionDefinitionService,
        PermissionService,
        CapitalizePipe,
        DataExportConfigurationService,
        FlatTreeService,
        ProductModelService,
        ProductModelPartService,
        StoreCartService,
        StoreOrderService,
        TreeNodeService,
        SparePartDefinitionService,
        StoreOrderProviderService,
        ProductModelSparePartDefinitionReferenceService,
        WearStatusService,
        UserActionStatusService,
        AddButtonContextService,
        ProductModelCategoryService,
        ExpandableWidgetDialogService,
        ExternalCatalogService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

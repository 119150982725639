import { HttpParams } from '@angular/common/http';
import { forwardRef, Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ProductModelPartService } from './product-model-part.service';
import { ProductModelSparePartDefinitionReferenceService } from './product-model-spare-part-definition-reference.service';

@Injectable()
export class ExternalCatalogService {

    private catalog: any;
    private eventListeners: Function[] = [];
    private renderer: Renderer2;
    private svgClickEventSubject: Subject<SvgClickEventData> = new Subject();

    constructor(
        @Inject(forwardRef(() => ProductModelSparePartDefinitionReferenceService)) private sparePartDefinitionReferenceService: ProductModelSparePartDefinitionReferenceService,
        @Inject(forwardRef(() => ProductModelPartService)) private productModelPartService: ProductModelPartService,
        @Inject(forwardRef(() => RendererFactory2)) private rendererFactory: RendererFactory2
    ) {
        this.catalog = {
            addSvgClickableElement: (svgElement: any, options: ExternalCatalogData) => this.addSvgClickableElement(svgElement, options),
        }
        this.renderer = this.rendererFactory.createRenderer(null, null);
    }

    getCatalog(): any {
        return this.catalog;
    }

    addSvgClickableElement(svgElement: any, options: ExternalCatalogData): void {
        if (svgElement && options?.technicalSchemePosition) {
            svgElement.setAttribute('class', options.clickableClass ? options.clickableClass : 'svg-clickable');
            this.addListenEvent(svgElement, options);
        }
    }

    private addListenEvent(svgElement: any, options: ExternalCatalogData): void {
        const listener: Function = this.renderer.listen(svgElement, 'click', (event) => {
            this.clickEventCheck(options);
        });
        this.eventListeners.push(listener);
    }

    removeAllEventListeners(): void {
        this.eventListeners?.forEach(listener => {
            listener();
        });
        this.eventListeners = [];
    }

    private clickEventCheck(options: ExternalCatalogData): void {
        this.sparePartDefinitionReferenceService.getPagedProductModelSparePartDefinitionReferences(0, 1, ['name', 'asc'], this.getProductModelSparePartDefinitionReferenceParams(options)).then(pagedSparePartDefinitions => {
            const sparePartDefinitionReference = pagedSparePartDefinitions.content.length > 0 ? pagedSparePartDefinitions.content[0] : null;
            if (sparePartDefinitionReference) {
                this.updateSvgClickEventSubject(sparePartDefinitionReference.sparePartDefinitionId, SvgClickEventDataType.OPEN_SPARE_PART_DEFINITION_DETAILS);
            } else {
                this.productModelPartService.getPagedProductModelParts(0, 1, ['name', 'asc'], this.getProductModelPartParams(options)).then(pagedProductModelParts => {
                    const productModelPart = pagedProductModelParts.content.length > 0 ? pagedProductModelParts.content[0] : null;
                    if (productModelPart) {
                        this.updateSvgClickEventSubject(productModelPart.id, SvgClickEventDataType.CHANGE_PRODUCT_MODEL_PART);
                    }
                }).catch(err => { /* do nothing */ });
            }
        }).catch(err => { /* do nothing */ });
    }

    private getProductModelSparePartDefinitionReferenceParams(options: ExternalCatalogData): HttpParams {
        let params = new HttpParams();
        if (options.productModelId) {
            params = params.set('productModelId', options.productModelId);
        }
        if (options.productModelPartId) {
            params = params.set('productModelPartId', options.productModelPartId);
        } else {
            params = params.set('productModelOnly', true);
        }
        params = params.set('technicalSchemePosition', options.technicalSchemePosition);
        return params;
    }

    private getProductModelPartParams(options: ExternalCatalogData): HttpParams {
        let params = new HttpParams();
        if (options.productModelId) {
            params = params.set('productModelId', options.productModelId);
        }
        if (options.productModelPartId) {
            params = params.set('parentProductModelPartId', options.productModelPartId);
        } else {
            params = params.set('rootsOnly', true);
        }
        params = params.set('technicalSchemePosition', options.technicalSchemePosition);
        return params;
    }

    private updateSvgClickEventSubject(elementId: string, type: SvgClickEventDataType): void {
        this.svgClickEventSubject.next({ elementId: elementId, eventType: type });
    }

    getSvgClickEventSubject(): Observable<SvgClickEventData> {
        return this.svgClickEventSubject.asObservable();
    }
}

export interface ExternalCatalogData {
    technicalSchemePosition: string;
    productModelId: string;
    productModelPartId: string;
    clickableClass: string;
}

export interface SvgClickEventData {
    elementId: string
    eventType: SvgClickEventDataType
}

export enum SvgClickEventDataType {
    OPEN_SPARE_PART_DEFINITION_DETAILS = "OPEN_SPARE_PART_DEFINITION_DETAILS",
    CHANGE_PRODUCT_MODEL_PART = "CHANGE_PRODUCT_MODEL_PART"
}
import { Component, EventEmitter, forwardRef, Inject, Input, OnInit, Output } from '@angular/core';
import { Alert, CustomPropertyDefinition, Location, User } from '../../model';
import { AuthenticationService } from '../../service/authentication.service';
import { CustomPropertyService, CustomPropertyType } from '../../service/custom-property.service';
import { LocalizationPipe } from '../../shared/pipe';
import { API_URL } from '../../common/setup'

@Component({
	selector: 'maintenance-registry-details',
	template: require('./maintenance-registry-details.component.html'),
	styles: [`

		.maintenance-details-container {
			padding: 24px 9px;
			gap: 24px;
		}

		.properties-container {
			background-color: #F5F5F5;
			padding: 10px;
			gap: 24px;
			display: flex;
			flex-direction: column;
		}

		.property-details {
			gap: 8px;
		}
  `]
})
export class MaintenanceRegistryDetailsComponent implements OnInit {

	@Input() maintenanceWork: Alert;

	@Input() writePermission: boolean;

	@Input() displayedColumnNames: string[];

	@Input() location: Location;

	@Output() editAction = new EventEmitter();

	@Output() deleteAction = new EventEmitter();

	user: User;
	groupPropertyMap: { [group: string]: CustomPropertyDefinition[] } = {};
	noGroupProperties: CustomPropertyDefinition[] = [];

	constructor(
		@Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
		@Inject(forwardRef(() => CustomPropertyService)) private customPropertyService: CustomPropertyService,
		@Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe
	) { }

	ngOnInit(): void {
		this.user = this.authenticationService.getUser();
		this.initGroups();
	}

	private initGroups(): void {
		let maintenanceWorkProperties = this.customPropertyService.getCustomPropertyDefinitionByType(CustomPropertyType.MaintenanceWork).filter(prop =>
			(this.maintenanceWork.locationAlertDefinitionId ? prop.locationAlertDefinitionId == this.maintenanceWork.locationAlertDefinitionId : prop.alertDefinitionId == this.maintenanceWork.alertDefinitionId)
			&& !this.displayedColumnNames.includes('properties.' + prop.name));
		if (this.maintenanceWork.properties) {
			Object.keys(this.maintenanceWork.properties).forEach(maintenanceProp => {
				if (!maintenanceWorkProperties.find(p => p.name == maintenanceProp)) {
					let dummyProperty: CustomPropertyDefinition = new CustomPropertyDefinition();
					dummyProperty.name = maintenanceProp;
					this.noGroupProperties.push(dummyProperty);
				}
			});
		}
		maintenanceWorkProperties.forEach(prop => {
			if (prop.group) {
				const localizedGroup = this.localizationPipe.transform(prop.group);
				if (this.groupPropertyMap[localizedGroup]?.length) {
					this.groupPropertyMap[localizedGroup].push(prop);
				} else {
					this.groupPropertyMap[localizedGroup] = [prop];
				}
			} else {
				this.noGroupProperties.push(prop);
			}
		});
	}

	edit(): void {
		this.editAction.emit(this.maintenanceWork);
	}

	openDeleteDialog(): void {
		this.deleteAction.emit(this.maintenanceWork.id);
	}

	isPropertyVisible(name: string): boolean {
		return !this.displayedColumnNames.includes(name);
	}

	getPropertyValue(property: CustomPropertyDefinition): string {
		if (!this.maintenanceWork.properties?.[property.name]) {
			return "";
		}
		if (property.values) {
			let dictionaryValue = property.values.find(dv => dv.value == this.maintenanceWork.properties[property.name])
			return dictionaryValue?.label || this.maintenanceWork.properties[property.name];
		}
		return this.maintenanceWork.properties[property.name];
	}

	getFileName(property: CustomPropertyDefinition): string {
		return JSON.parse(this.maintenanceWork.properties[property.name])["fileName"];
	}

	downloadFile(property: CustomPropertyDefinition): void {
		this.customPropertyService.downloadFile(this.maintenanceWork.id, property.id, CustomPropertyType.MaintenanceWork);
	}
}

import { AfterContentInit, Component, ContentChildren, forwardRef, Inject, Input, OnInit, QueryList, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { take } from 'rxjs';
import { ErrorMessages } from '../../common/constants';
import { PropertyInfo } from '../../common/properties';
import { CustomMatPaginatorIntl } from '../../dashboard-area/shared/custom-mat-paginator-intl.service';
import { ActionDefinition, ActionState, ActionStatus, ActivationType, Thing } from '../../model';
import { AbstractExportContextService } from '../../service/abstract-export-context.service';
import { ActionContextService } from '../../service/action-context.service';
import { AppService } from '../../service/app.service';
import { AuthenticationService } from '../../service/authentication.service';
import { FieldService } from '../../service/field.service';
import { NavigationService } from '../../service/navigation.service';
import { ThingContextService } from '../../service/thing-context.service';
import { AbstractContextService } from '../../shared/class/abstract-context-service.class';
import { ConfirmDialog } from '../../shared/confirm-dialog/confirm-dialog.component';
import { ButtonActionValue, CustomTableService } from '../../shared/custom-table';
import { DatetimeFormatterPipe, LocalizationPipe } from '../../shared/pipe';
import { COMPONENT_DEFINITION_REF } from "../../shared/utility/component-definition-token";
import { ErrorUtility } from '../../utility/error-utility';
import { ActionInfoDialogComponent } from '../action-info-dialog/action-info-dialog.component';
import { GroupedActionListService } from '../grouped-action-list/grouped-action-list.service';
import { ListWidgetV2Component } from '../list-widget-v2/list-widget-v2.components';
import { ThingListWidgetV2Service } from '../thing-list/thing-list-widget-v2.service';
import { ActionListWidgetReschedulingDialogComponent } from './action-list-widget-rescheduling-dialog.component';
import { ActionListWidgetService } from './action-list-widget.service';

@Component({
    selector: 'action-list-widget',
    template: require('./action-list-widget.component.html'),
    styles: [require('../list-widget-v2/list-widget-v2.css'), require('./action-list-widget.component.css')],
    providers: [ActionListWidgetService, GroupedActionListService, ThingListWidgetV2Service, { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl }]
})
export class ActionListWidgetComponent extends ListWidgetV2Component<ActionStatus> implements OnInit, AfterContentInit {

    @Input() searchFields: string[] = ['state', 'actionDefinition.name'];

    @Input() status: string[];

    @Input() topic: string[];

    @Input() type: string[] = [];

    @Input() priority: string[];

    @ContentChildren(COMPONENT_DEFINITION_REF) private columnComponents: QueryList<any>;

    @ViewChild(ActionListWidgetReschedulingDialogComponent) reschedulingDialog: ActionListWidgetReschedulingDialogComponent;

    hidden: boolean;

    private actionStatuses: ActionStatus[];
    private actionDefinitions: ActionDefinition[] = [];

    private defaultProperties: { [name: string]: PropertyInfo } = {
        icon: { label: '', path: 'actionDefinition.iconUrl', defaultFilter: null, defaultSorting: null },
        state: { label: 'statusProperty', path: 'state', defaultFilter: null, defaultSorting: null },
        "actionDefinition.name": { label: 'actionDefinitionProperty', path: 'actionDefinition.name', defaultFilter: null, defaultSorting: null },
        startTimestamp: { label: 'startDateProperty', path: 'startTimestamp', defaultFilter: DatetimeFormatterPipe, defaultSorting: null }
    };

    constructor(
        @Inject(forwardRef(() => ActionListWidgetService)) private actionListWidgetService: ActionListWidgetService,
        @Inject(forwardRef(() => AuthenticationService)) authenticationService: AuthenticationService,
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe,
        @Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
        @Inject(forwardRef(() => ActionContextService)) private actionContextService: ActionContextService,
        @Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService,
        @Inject(forwardRef(() => AppService)) appService: AppService,
        @Inject(forwardRef(() => GroupedActionListService)) private groupedActionListService: GroupedActionListService,
        @Inject(forwardRef(() => FieldService)) private fieldService: FieldService,
        @Inject(forwardRef(() => ThingListWidgetV2Service)) private thingListWidgetService: ThingListWidgetV2Service,
        @Inject(forwardRef(() => AbstractExportContextService)) exportService: AbstractExportContextService,
        @Inject(forwardRef(() => ThingContextService)) private thingContextService: ThingContextService,
        @Inject(forwardRef(() => MatDialog)) private dialog: MatDialog,
        @Inject(forwardRef(() => ViewContainerRef)) private vcRef: ViewContainerRef
    ) {
        super(appService, authenticationService, exportService, null);
    }

    ngOnInit() {
        this.checkIfHidden();
        this.readPermission = this.authenticationService.isCustomerUser() || this.authenticationService.isLocationUser()
            || ((this.authenticationService.isOrganizationUser() || this.authenticationService.isPartnerUser()) && !!this.contextService.getCurrentCustomer());
        if (this.actionContextService.getCurrentActionDefinition()) {
            const actionDefinition: ActionDefinition = this.actionContextService.getCurrentActionDefinition();
            this.type = [actionDefinition.name];
            this.topic = [actionDefinition.topic];
            this.status = this.actionContextService.getCurrentStatuses() ? this.actionContextService.getCurrentStatuses() : null;
        }
    }

    private checkIfHidden(): void {
        if (!this.thingContextService.getCurrentThing()) {
            if (this.authenticationService.isOrganizationUser() || this.authenticationService.isPartnerUser()) {
                this.hidden = !this.authenticationService.hasFeature('multipleThingAggregationForOrgPartner');
            } else {
                this.hidden = !this.authenticationService.hasFeature('multipleThingAggregationForCustomer');
            }
        }
    }

    ngAfterContentInit(): void {
        if (this.readPermission) {
            this.getDisplayedColumns([]);
            if (!this.sort) {
                this.sort = this.actionListWidgetService.setDefaultSort(this.displayedColumns);
            }
            if (this.queryFieldRef) {
                this.fieldServiceSubscription = this.fieldService.subscribeToFields([this.queryFieldRef]).subscribe(fieldsMap => this.manageFilterValue(fieldsMap));
            }
            this.groupedActionListService.getAllActionDefinition().then(actionDefinitions => {
                this.actionDefinitions = actionDefinitions;
                this.getActionList();
            }).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR));
        }
    }

    private getDisplayedColumns(actionStatuses: ActionStatus[]): void {
        this.displayedColumns = this.actionListWidgetService.getVisibleColumns(this.columnComponents.toArray(), this.defaultProperties, 'ActionStatus');
        let iconValueMapDone = {
            "_default": {
                isFontAwesome: false,
                customIconClass: 'material-icons action-mark-as-done-icon text-primary',
                customIconHtml: 'check_circle'
            }
        }
        let iconValueMapDiscard = {
            "_default": {
                isFontAwesome: false,
                customIconClass: 'material-icons action-discard-icon text-danger',
                customIconHtml: 'cancel'
            }
        }
        let iconValueMapReschedule = {
            "_default": {
                isFontAwesome: false,
                customIconClass: 'material-icons action-reschedule-icon text-default',
                customIconHtml: 'next_plan'
            }
        }
        let iconValueMapInfo = {
            "_default": {
                isFontAwesome: false,
                customIconClass: 'material-icons action-info-icon text-default',
                customIconHtml: 'info'
            }
        }
        this.displayedColumns.push(
            CustomTableService.newIconColumn('complete', '', 'showComplete', iconValueMapDone).withVisiblePath('showComplete').withStyle({ '_any': { 'padding-top': '4px', 'width': '1%' } })
        );
        this.displayedColumns.push(
            CustomTableService.newIconColumn('discard', '', 'showDiscard', iconValueMapDiscard).withVisiblePath('showDiscard').withStyle({ '_any': { 'padding-top': '4px', 'width': '1%' } })
        );
        if (actionStatuses?.some(a => a['showReschedule'])) {
            this.displayedColumns.push(
                CustomTableService.newIconColumn('reschedule', '', 'showReschedule', iconValueMapReschedule).withVisiblePath('showReschedule').withStyle({ '_any': { 'padding-top': '4px', 'width': '1%' } })
            );
        }
        this.displayedColumns.push(
            CustomTableService.newIconColumn('info', '', 'showInfo', iconValueMapInfo).withStyle({ '_any': { 'padding-top': '4px', 'width': '1%' } })
        );
    }

    private getActionList(): void {
        if (!this.advancedSearchBody) {
            this.advancedSearchBody = {};
        }
        if (this.query) {
            this.getEncodedQueryFields();
        } else {
            this.advancedSearchBody['states'] = this.status;
            this.advancedSearchBody['topics'] = this.topic;
            this.advancedSearchBody['priorities'] = this.priority;
            this.advancedSearchBody['actionDefinitionIds'] = this.getActionDefinitionIdsFromNames();
        }
        this.actionListWidgetService.getPagedList(this.pageIndex, this.pageSize, this.sort, null, this.searchFields, this.advancedSearchBody).then(pagedList => {
            this.actionStatuses = pagedList.content;
            this.setIconsVisiblity(pagedList.content);
            this.updateRowClass(pagedList.content);
            this.getDisplayedColumns(pagedList.content);
            let things: Thing[] = pagedList.content.filter(as => as.thing).map(as => { return as.thing });
            this.thingListWidgetService.addTags(things, this.contextService.getTagObjects());
            this.updateElementList(pagedList);
            this.error = null;
        }).catch(err => this.error = ErrorUtility.getMessage(err));
    }

    private getActionDefinitionIdsFromNames(): string[] {
        let ids = [];
        if (this.type && this.actionDefinitions?.length) {
            this.type.forEach(actionName => {
                if (this.actionDefinitions.find(definition => actionName == definition.name)) {
                    ids.push(this.actionDefinitions.find(definition => actionName == definition.name).id);
                }
            });
        }
        return ids;
    }

    refreshList(data?: { pageIndex: number, pageSize: number, advancedSearchBody: any, sort: string[] }): void {
        if (data) {
            this.pageIndex = data.pageIndex;
            this.pageSize = data.pageSize;
            this.advancedSearchBody = data.advancedSearchBody;
            this.sort = data.sort;
        }
        this.getActionList();
    }

    private setIconsVisiblity(elements: ActionStatus[]): any {
        elements.forEach(el => {
            el['showComplete'] = el.state == ActionState.TODO ? this.localizationPipe.transform('Mark as Done') : null;
            el['showDiscard'] = el.state == ActionState.TODO ? this.localizationPipe.transform('Discard') : null;
            el['showReschedule'] = el.state == ActionState.TODO && el.actionDefinition?.activationType == ActivationType.PERIOD && el.actionDefinition?.reschedulable ? this.localizationPipe.transform('Reschedule') : null;
            el['showInfo'] = this.localizationPipe.transform('Info');
        });
        return elements;
    }

    execButtonAction(actionValue: ButtonActionValue): void {
        if (actionValue.action == 'complete') {
            const action = this.actionStatuses[actionValue.index];
            if (action.actionDefinition?.doneEventDefinitionId) {
                this.openActionInfoDialog(action);
            } else {
                this.openConfirmDialog(actionValue.index, ActionState.DONE);
            }
        } else if (actionValue.action == 'discard') {
            this.openConfirmDialog(actionValue.index, ActionState.DISCARDED);
        } else if (actionValue.action == 'reschedule') {
            this.reschedulingDialog.open(this.actionStatuses[actionValue.index]);
        } else if (actionValue.action == 'info') {
            this.openActionInfoDialog(this.actionStatuses[actionValue.index]);
        }
    }

    private updateActionState(body: any, actionStatus: ActionStatus): void {
        this.actionListWidgetService.updateAction(actionStatus, body).then(() => {
            this.getActionList();
            this.actionContextService.updateRefreshEventSubject(true);
        }).catch(err => this.error = ErrorUtility.getMessage(err));
    }

    goToDetail(status: ActionStatus): void {
        const actionStatus = this.actionStatuses.find(el => el.id == status?.id);
        if (actionStatus && actionStatus.thingId) {
            this.navigationService.goToThingDetailPage(actionStatus.thingId);
        }
    }

    private getEncodedQueryFields(): void {
        let fields = [];
        this.query.forEach(el => {
            if (el.property == "status") {
                fields["states"] = el.value;
            } else {
                fields[el.property] = el.value;
            }
        });
        Object.assign(this.advancedSearchBody, fields);
    }

    private manageFilterValue(fieldsMap: { [fields: string]: any }): void {
        let advancedSearchBody = fieldsMap[this.queryFieldRef];
        if (advancedSearchBody) {
            this.executeAdvancedSearch(advancedSearchBody);
        }
    }

    ngOnDestroy(): void {
        if (this.queryFieldRef) {
            this.fieldService.unsubscribeFromFields([this.queryFieldRef]);
        }
        if (this.fieldServiceSubscription) {
            this.fieldServiceSubscription.unsubscribe();
        }
    }

    private updateRowClass(elements: ActionStatus[]): ActionStatus[] {
        if (elements?.length > 0) {
            elements.forEach(as => {
                as['rowClass'] = as.actionDefinition?.priority;
            });
        }
        return elements;
    }

    protected subscribeToExportServices(): void {
        // do nothing, export not supported
    }

    rescheduleAction(rescheduleBody: any): void {
        const actionStatusId = rescheduleBody.actionStatusId;
        delete rescheduleBody.actionStatusId;
        this.updateActionState(rescheduleBody, this.actionStatuses.find(el => el.id == actionStatusId));
    }

    private openConfirmDialog(index: number, state: ActionState): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.minWidth = '25%';
        dialogConfig.panelClass = state == ActionState.DISCARDED ? "discard-action-confirm-dialog" : "mark-as-done-action-confirm-dialog";
        dialogConfig.autoFocus = false;
        dialogConfig.data = {
            title: state == ActionState.DISCARDED ? "discardActionMessage" : "markAsDoneActionMessage",
            message: state == ActionState.DISCARDED ? "discardActionInfoMessage" : "markAsDoneActionInfoMessage"
        }
        this.dialog.open(ConfirmDialog, dialogConfig).afterClosed().pipe(take(1)).subscribe(result => {
            if (result) {
                this.updateActionState({ state: state }, this.actionStatuses[index]);
            }
        });
    }

    private openActionInfoDialog(actionStatus: ActionStatus): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.minWidth = '25%';
        dialogConfig.maxWidth = '428px';
        dialogConfig.autoFocus = false;
        dialogConfig.data = { actionStatus: actionStatus };
        dialogConfig.viewContainerRef = this.vcRef;
        this.dialog.open(ActionInfoDialogComponent, dialogConfig).afterClosed().pipe(take(1)).subscribe(refresh => {
            if (refresh) {
                this.getActionList();
                this.actionContextService.updateRefreshEventSubject(true);
            }
        });
    }
}
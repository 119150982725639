import { Component, Inject, Input, OnInit, ViewChild, forwardRef } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { Sort } from "@angular/material/sort";
import { ErrorMessages } from "../../common/constants";
import { ListRangeSelectionModeType, ProductModel, SparePartDefinitionCategory, StoreCatalogLayoutMode, StoreOrderItem, Thing, ThingInventoryManagementType } from "../../model";
import { SparePartDefinition } from "../../model/spare-part-definition";
import { AuthenticationService } from "../../service/authentication.service";
import { FlatTreeNode, FlatTreeService } from "../../service/flat-tree.service";
import { ProductModelService } from "../../service/product-model.service";
import { StoreCartService } from "../../service/store-cart.service";
import { StoreOrderProviderService } from "../../service/store-order-provider.service";
import { UserThingService } from "../../service/user-thing.service";
import { AbstractThingContextService } from "../../shared/class/abstract-thing-context-service.class";
import { MessageComponent } from "../../shared/component";
import { SimpleSearchComponent } from "../../shared/component/advanced-search/simple-search/simple-search.component";
import { ErrorUtility } from "../../utility/error-utility";
import { SparePartDefinitionService } from "../shared/spare-part-definition.service";
import { SparePartDefinitionCatalogDetailsDialogComponent } from "./catalog-details-dialog/spare-part-definition-catalog-details-dialog/spare-part-definition-catalog-details-dialog.component";
import { ProductModelTechnicalSchemeSelectorComponent } from "./product-model-scheme-selector/product-model-technical-scheme-selector.component";

@Component({
    selector: 'catalog-widget',
    template: require('./catalog-widget.component.html'),
    styles: [require('./catalog-widget.component.css')]
})
export class CatalogWidgetComponent implements OnInit {

    @Input() layoutMode: StoreCatalogLayoutMode = StoreCatalogLayoutMode.GRID;

    @Input() title: string;

    @Input() sort: string[] = ['name', 'asc'];

    @Input() pageSize: number = 40;

    @ViewChild(SimpleSearchComponent) private simpleSearch: SimpleSearchComponent;

    @ViewChild('saveMessage') saveMessage: MessageComponent;

    @ViewChild(ProductModelTechnicalSchemeSelectorComponent) productModelPartTree: ProductModelTechnicalSchemeSelectorComponent;

    sparePartDefinitions: SparePartDefinition[];
    categoryFlatTree: FlatTreeNode[];
    error: string;
    pageIndex: number = 0;
    totalPages: number;
    length: number;
    searchString: string;
    loaded: boolean;
    productModels: ProductModel[];
    things: Thing[] = [];
    showThings: boolean;
    productModelControl = new FormControl({ value: null });
    thingControl = new FormControl({ value: null });
    categoryControl = new FormControl({ value: null });
    filtered: boolean;
    cartUpdating: boolean;
    addToCartEnabled: boolean;
    selectedProductModelId: string;
    currentThing: Thing;
    invalidThing: boolean;
    matPaginatorClass: string;
    showProductModels: boolean;
    showTechnicalSchemePositions: boolean;
    hidePrices: boolean;

    // filler loading properties
    fillerColumnCount: number;
    fillerRowCount: number;
    fillerRowHeight: number;
    fillerRowHeightWithSpace: number;
    fillerRowOffset: number;
    fillerColumnWidth: number;
    fillerRowWidthWithSpace: number;

    private selectedCategoryId: string;
    private categories: SparePartDefinitionCategory[];
    private allThings: Thing[] = [];
    private selectedProductModelPartId: string;

    constructor(
        @Inject(forwardRef(() => SparePartDefinitionService)) private catalogService: SparePartDefinitionService,
        @Inject(forwardRef(() => FlatTreeService)) private flatTreeService: FlatTreeService,
        @Inject(forwardRef(() => ProductModelService)) private productModelService: ProductModelService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => UserThingService)) private userThingService: UserThingService,
        @Inject(forwardRef(() => StoreCartService)) private storeCartService: StoreCartService,
        @Inject(forwardRef(() => AbstractThingContextService)) private thingContextService: AbstractThingContextService,
        @Inject(forwardRef(() => StoreOrderProviderService)) private storeOrderProviderService: StoreOrderProviderService,
        @Inject(forwardRef(() => MatDialog)) private dialog: MatDialog
    ) { }

    ngOnInit(): void {
        this.showProductModels = this.authenticationService.getThingInventoryManagement() != ThingInventoryManagementType.BY_THING_DEFINITION;
        this.initFillerLoadingProperties();
        this.currentThing = this.thingContextService.getCurrentThing();
        if (this.currentThing && !this.currentThing.productModelId && !this.currentThing.productModelPartId) {
            this.invalidThing = true;
        }
        this.addToCartEnabled = this.storeCartService.canAddToCart();
        this.updatePaginatorClass();
        if (!this.invalidThing) {
            let promises = [];
            promises.push(this.catalogService.getSparePartDefinitionCategories().then(categories => this.categories = categories));
            if (this.showProductModels) {
                promises.push(this.productModelService.getProductModelsAssociatedToThings().then(productModels => this.productModels = productModels));
            }
            if (this.authenticationService.isCustomerUser() && !this.currentThing) {
                this.showThings = true;
                promises.push(this.userThingService.getRecursivelyAllThings().then(things => {
                    this.allThings = things.filter(t => t.productModelId);
                    this.things = this.allThings;
                }));
            }
            if (!this.authenticationService.isOrganizationUser()) {
                promises.push(this.storeOrderProviderService.getStoreOrderProviders().then(providers => {
                    this.hidePrices = providers[0].pricingHiddenToCustomers;
                }));
            }
            Promise.all(promises).then(() => {
                const tree = this.catalogService.fillSparePartDefinitionCategoryTreeNodes(this.categories);
                this.categoryFlatTree = this.flatTreeService.getFlatTree(tree);
                if (this.currentThing) {
                    this.filterByCurrentThing();
                } else {
                    this.getSparePartDefinitionList();
                }
            }).catch(err => this.error = ErrorUtility.getMessage(err));
        }
    }

    private updatePaginatorClass(): void {
        if (this.authenticationService.getTenant().listRangeSelectionMode == ListRangeSelectionModeType.PAGES) {
            this.matPaginatorClass = "mat-paginator-pages-mode";
        }
    }

    private initFillerLoadingProperties(): void {
        if (this.layoutMode == StoreCatalogLayoutMode.GRID) {
            this.fillerColumnCount = 4;
            this.fillerRowCount = 3;
            this.fillerRowHeight = 80;
            this.fillerRowHeightWithSpace = 85;
            this.fillerRowOffset = 5;
            this.fillerColumnWidth = 95;
            this.fillerRowWidthWithSpace = 100;
        } else {
            this.fillerRowCount = 40;
            this.fillerRowHeight = 10;
            this.fillerRowHeightWithSpace = 12;
            this.fillerRowOffset = 10;
        }
    }

    private getSparePartDefinitionList(): void {
        this.loaded = false;
        this.filtered = !!this.selectedCategoryId || !!this.selectedProductModelId || !!this.searchString;
        this.catalogService.getPagedList(this.pageIndex, this.pageSize, this.sort, this.selectedCategoryId, this.selectedProductModelId, this.searchString, this.selectedProductModelPartId).then(pagedList => {
            this.sparePartDefinitions = pagedList.content;
            this.length = pagedList.totalElements;
            this.pageSize = pagedList.size;
            this.pageIndex = pagedList.number;
            this.totalPages = pagedList.totalPages;
            this.loaded = true;
            this.error = null;
        }).catch(err => this.error = ErrorUtility.getMessage(err));
    }

    filterByCategory(categoryId: string): void {
        this.selectedCategoryId = categoryId;
        this.getSparePartDefinitionList();
    }

    search(advancedSearchBody: any): void {
        this.searchString = advancedSearchBody.key;
        this.getSparePartDefinitionList();
    }

    changePage(pageEvent: PageEvent): void {
        this.pageIndex = pageEvent.pageIndex;
        this.getSparePartDefinitionList();
    }

    filterByProductModel(productModelId: string): void {
        this.thingControl.reset();
        this.selectedProductModelId = productModelId;
        if (productModelId) {
            this.things = this.allThings.filter(t => t.productModelId == productModelId);
        } else {
            this.things = this.allThings;
            this.getSparePartDefinitionList();
        }
    }

    filterByThing(thingId: string): void {
        if (thingId) {
            const thing = this.things.find(t => t.id == thingId);
            this.productModelControl.setValue(thing.productModelId);
            this.selectedProductModelId = thing.productModelId;
        } else {
            this.productModelControl.reset();
            this.selectedProductModelId = null;
        }
        this.getSparePartDefinitionList();
    }

    resetFilters(): void {
        this.thingControl.reset();
        this.categoryControl.reset();
        this.simpleSearch.reset();
        this.things = this.allThings;
        this.selectedCategoryId = null;
        this.searchString = null;
        if (!this.currentThing) {
            this.productModelControl.reset();
            this.selectedProductModelId = null;
            this.selectedProductModelPartId = null;
            this.showTechnicalSchemePositions = false;
            this.getSparePartDefinitionList();
        } else if (this.currentThing.productModelId) {
            this.productModelPartTree.resetSelectedProductModelPartId();
        } else if (this.currentThing.productModelPartId) {
            this.getSparePartDefinitionList();
        }
    }

    addToCart(item: StoreOrderItem): void {
        this.cartUpdating = true;
        const thing = this.currentThing || this.getSelectedThing();
        item.thingId = thing?.id;
        this.storeCartService.addItemToCart(item).then(() => {
            this.cartUpdating = false;
            this.saveMessage.show();
            this.error = null;
        }).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.SAVE_DATA_ERROR));
    }

    private getSelectedThing(): Thing {
        const id = this.thingControl.value;
        if (id) {
            return this.things.find(t => t.id == id);
        } else {
            return null;
        }
    }

    filterByProductModelPart(body: { id: string, hasTechnicalSchemes: boolean }): void {
        this.selectedProductModelPartId = body.id;
        this.showTechnicalSchemePositions = body.hasTechnicalSchemes;
        this.getSparePartDefinitionList();
    }

    private filterByCurrentThing(): void {
        if (this.currentThing.productModelPartId) {
            this.selectedProductModelId = this.currentThing.productModelPart.productModelId;
        } else if (this.currentThing.productModelId) {
            this.selectedProductModelId = this.currentThing.productModelId;
        }
        this.productModelControl.setValue(this.selectedProductModelId);
    }

    changeSort(sort: Sort): void {
        this.sort = [sort.active, sort.direction];
        this.getSparePartDefinitionList();
    }

    openSparePartDefinitionDetails(sparePartDefinitionId: string): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.width = "600px";
        dialogConfig.data = {
            sparePartDefinitionId: sparePartDefinitionId,
            thing: this.currentThing || this.getSelectedThing()
        };
        this.dialog.open(SparePartDefinitionCatalogDetailsDialogComponent, dialogConfig);
    }

}
import { Component, ContentChildren, forwardRef, Host, Inject, Input, QueryList, ViewContainerRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { take } from 'rxjs';
import { ErrorMessages, Permissions } from '../../common/constants';
import { PropertyInfo } from '../../common/properties';
import { Alert, ListRangeSelectionModeType, Location, LocationAlertDefinition, PagedList, Thing } from '../../model';
import { AlertDefinitionService } from '../../service/alert-definition.service';
import { AppService } from '../../service/app.service';
import { AuthenticationService } from '../../service/authentication.service';
import { AbstractContextService } from '../../shared/class/abstract-context-service.class';
import { AbstractThingContextService } from '../../shared/class/abstract-thing-context-service.class';
import { PropertyComponent } from '../../shared/component/property/property.component';
import { ConfirmDialog } from '../../shared/confirm-dialog/confirm-dialog.component';
import { COMPONENT_DEFINITION_REF } from '../../shared/utility/component-definition-token';
import { ErrorUtility } from '../../utility/error-utility';
import { ListWidgetV2Component } from '../list-widget-v2/list-widget-v2.components';
import { MaintenanceRegistryDialogComponent } from './maintenance-registry-dialog.component';
import { MaintenanceRegistryWidgetService } from './maintenance-registry-widget.service';

@Component({
	selector: 'maintenance-registry-widget',
	template: require('./maintenance-registry-widget.component.html'),
	providers: [MaintenanceRegistryWidgetService]
})
export class MaintenanceRegistryWidgetComponent extends ListWidgetV2Component<Alert> {

	@Input() showHeader: boolean;

	@ContentChildren(COMPONENT_DEFINITION_REF) private columnComponents: QueryList<PropertyComponent>;

	displayedColumnNames: string[];
	locationThings: Thing[];
	thing: Thing;
	location: Location;

	private locationAlertDefinitions: LocationAlertDefinition[];
	private defaultProperties: { [name: string]: PropertyInfo } = {
		startTimestamp: { label: 'dateProperty', path: 'startTimestamp', defaultFilter: null, defaultSorting: null },
		type: { label: 'typeProperty', path: 'name', defaultFilter: null, defaultSorting: null },
		userEmail: { label: 'userProperty', path: 'activateUserEmail', defaultFilter: null, defaultSorting: null },
		duration: { label: 'testDurationProperty', path: null, defaultFilter: null, defaultSorting: null }
	};

	constructor(
		@Inject(forwardRef(() => AbstractThingContextService)) @Host() private thingContextService: AbstractThingContextService,
		@Inject(forwardRef(() => AuthenticationService)) authenticationService: AuthenticationService,
		@Inject(forwardRef(() => MaintenanceRegistryWidgetService)) private maintenanceRegistryService: MaintenanceRegistryWidgetService,
		@Inject(forwardRef(() => AbstractContextService)) private contextService: AbstractContextService,
		@Inject(forwardRef(() => AppService)) appService: AppService,
		@Inject(forwardRef(() => MatDialog)) private dialog: MatDialog,
		@Inject(forwardRef(() => AlertDefinitionService)) private alertDefinitionService: AlertDefinitionService,
		@Inject(forwardRef(() => ViewContainerRef)) private vcRef: ViewContainerRef
	) {
		super(appService, authenticationService, null, null);
	}

	ngOnInit(): void {
		this.title = this.title || 'maintenanceRegistryProperty';
		this.pageSize = 10;
		if (this.showHeader === undefined) {
			this.showHeader = true;
		}
		if (this.thingContextService.getCurrentThing()) {
			this.thing = this.thingContextService.getCurrentThing();
		} else {
			this.location = this.contextService.getCurrentLocation();
		}
		this.readPermission = this.authenticationService.hasPermission(Permissions.READ_MAINTENANCE_REGISTRY) && (!!this.thing || !!this.location);
		this.writePermission = this.authenticationService.hasPermission(Permissions.WRITE_MAINTENANCE_REGISTRY);
		this.initAlertDefinitions();
	}

	private initAlertDefinitions(): Promise<any> {
		if (!this.thing) {
			return this.alertDefinitionService.getLocationAlertDefintions().then(defs =>
				this.locationAlertDefinitions = defs.filter(ad => ad.manualHistoricization && ad.eventSeverity == 5))
				.catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR));
		} else {
			return Promise.resolve();
		}
	}

	protected updatePaginatorClass(): void {
		this.matPaginatorClass = "mat-paginator-hidden-last ";
		if (this.authenticationService.getTenant().listRangeSelectionMode == ListRangeSelectionModeType.PAGES) {
			this.matPaginatorClass += "mat-paginator-pages-mode";
		} else {
			this.matPaginatorClass += "mat-paginator-hidden-range-label";
		}
	}

	ngAfterContentInit(): void {
		this.displayedColumns = this.maintenanceRegistryService.getVisibleColumns(this.columnComponents.toArray(), this.defaultProperties, 'MaintenanceWork');
		this.displayedColumnNames = this.displayedColumns.map(dc => { return dc.name });
		if (this.location) {
			this.maintenanceRegistryService.getThingsByLocationId(this.location.id).then(things => {
				this.locationThings = things;
				this.refreshList();
			}).catch(err => ErrorUtility.getMessage(err, ErrorMessages.GET_DATA_ERROR));
		} else if (this.thing) {
			this.refreshList();
		}
	}

	private getAlertList(): void {
		this.maintenanceRegistryService.getPagedList(this.pageIndex, this.pageSize).then(pagedList => {
			this.error = null;
			this.updateElementList(pagedList);
		}).catch(err => this.error = ErrorUtility.getMessage(err));
	}

	updateElementList(pagedList: PagedList<Alert>): void {
		this.elements = pagedList.content;
		this.fillerRowCount = pagedList.numberOfElements;
		this.dataSource = new MatTableDataSource<Alert>(this.elements);
		this.length = pagedList.totalElements;
		this.pageSize = pagedList.size;
		this.pageIndex = pagedList.number;
		this.totalPages = pagedList.totalPages;
		this.loaded = true;
	}

	refreshList(data?: { pageIndex: number, pageSize: number, advancedSearchBody: any, sort: string[] }): void {
		this.loaded = false;
		if (data) {
			this.pageIndex = data.pageIndex;
			this.pageSize = data.pageSize;
			this.sort = data.sort;
		}
		this.getAlertList();
	}

	addMaintenance(): void {
		this.editMaintenance(null);
	}

	editMaintenance(maintenanceWork: Alert): void {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.minWidth = '25%';
		dialogConfig.panelClass = "edit-maintenance-work-dialog";
		dialogConfig.autoFocus = false;
		dialogConfig.viewContainerRef = this.vcRef;
		dialogConfig.data = {
			thing: this.thing,
			location: this.location,
			locationThings: this.locationThings,
			locationAlertDefinitions: this.locationAlertDefinitions,
			maintenanceWork: maintenanceWork
		}

		this.dialog.open(MaintenanceRegistryDialogComponent, dialogConfig).afterClosed().pipe(take(1)).subscribe(result => {
			if (result) {
				this.getAlertList();
			}
		});
	}

	deleteMaintenance(id: string): void {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.minWidth = '25%';
		dialogConfig.panelClass = "delete-maintenance-work-dialog";
		dialogConfig.autoFocus = false;
		dialogConfig.data = {
			title: "deleteMaintenanceWorkAlertTitle",
			message: "deleteMaintenanceWorkAlertInfoMessage"
		}

		this.dialog.open(ConfirmDialog, dialogConfig).afterClosed().pipe(take(1)).subscribe(result => {
			if (result) {
				this.maintenanceRegistryService.deleteMaintenanceWork(id).then(() => this.getAlertList())
					.catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.DELETE_DATA_ERROR));
			}
		});
	}

	protected goToDetail(element: Alert): void {
		// do nothing
	}

	protected subscribeToExportServices(): void {
		// do nothing
	}

}

import { Component, EventEmitter, Inject, Input, OnInit, Output, forwardRef } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { take } from "rxjs";
import { ErrorMessages, Permissions } from '../../common/constants';
import { AuditEvent } from "../../model/audit-event";
import { AuthenticationService } from "../../service/authentication.service";
import { ContextService } from "../../service/context.service";
import { CustomLabelService } from "../../service/custom-label.service";
import { DataService } from "../../service/data.service";
import { NavigationService } from "../../service/navigation.service";
import { ConfirmDialog } from "../../shared/confirm-dialog/confirm-dialog.component";
import { LoaderPipe, LocalizationPipe } from "../../shared/pipe";
import { ErrorUtility } from "../../utility/error-utility";

@Component({
    selector: 'audit-event-detail',
    template: require('./audit-event-detail.component.html'),
    styles: [`
        .outer-container {
            gap: 32px;
        }
        .outer-container-margin {
            margin: 24px; 
        }
        .details-container {
            padding: 10px;
            border-radius: 4px;
            background-color: #64c9d11A;         
        }
    `]
})
export class AuditEventDetailComponent implements OnInit {

    @Input() auditEvent: AuditEvent;

    @Input() detailsFilter: string;

    @Input() isMobile: boolean;

    @Output() navigateToResource = new EventEmitter();

    @Output() refreshAction = new EventEmitter();

    details: string;
    resourcePath: string[] = [];
    rollbackMetricValueInfo: { rollbackable: boolean, oldValue: string, timestamp: number, metricName: string };
    error: string;

    constructor(
        @Inject(forwardRef(() => LoaderPipe)) private loaderPipe: LoaderPipe,
        @Inject(forwardRef(() => NavigationService)) private navigationService: NavigationService,
        @Inject(forwardRef(() => ContextService)) protected contextService: ContextService,
        @Inject(forwardRef(() => AuthenticationService)) private authenticationService: AuthenticationService,
        @Inject(forwardRef(() => DataService)) private dataService: DataService,
        @Inject(forwardRef(() => MatDialog)) private dialog: MatDialog,
        @Inject(forwardRef(() => LocalizationPipe)) private localizationPipe: LocalizationPipe,
        @Inject(forwardRef(() => CustomLabelService)) private labelService: CustomLabelService,
        @Inject(forwardRef(() => MatSnackBar)) private snackBar: MatSnackBar
    ) { }

    ngOnInit(): void {
        if (this.detailsFilter) {
            this.details = this.loaderPipe.transform(this.auditEvent, this.detailsFilter, true);
        } else {
            this.details = this.auditEvent.message;
        }
        this.computeResourceUrl();
        this.computeRollbackableInfo();
    }

    private computeResourceUrl(): void {
        if (this.auditEvent.thingId) {
            this.resourcePath = ['/dashboard/thing_details', this.auditEvent.thingId];
        } else if (this.auditEvent.userId) {
            this.computeUserResource();
        } else {
            this.computeBusinessEntityResource();
        }
    }

    private computeUserResource() {
        if (!this.authenticationService.hasPermission(Permissions.READ_USER)) {
            return;
        }
        if (this.auditEvent.locationId) {
            if (!this.authenticationService.checkPermission(Permissions.READ_LOCATION)) {
                return;
            }
        } else if (this.auditEvent.customerId) {
            if (
                !(this.authenticationService.isCustomerUser() && this.authenticationService.checkPermission(Permissions.WRITE_CUSTOMER)) &&
                !(!this.authenticationService.isCustomerUser() && this.authenticationService.checkPermission(Permissions.READ_CUSTOMER))
            ) {
                return;
            }
        } else if (this.auditEvent.partnerId) {
            if (!this.authenticationService.checkPermission(Permissions.READ_PARTNER)) {
                return;
            }
        } else if (this.auditEvent.organizationId) {
            if (!this.authenticationService.checkPermission(Permissions.READ_ORGANIZATION)) {
                return;
            }
        } else {
            return;
        }

        this.resourcePath = ['/dashboard/users', this.auditEvent.userId];
    }

    computeBusinessEntityResource() {
        if (this.auditEvent.locationId) {
            if (this.authenticationService.isLocationUser()) {
                if (this.authenticationService.checkPermission(Permissions.WRITE_LOCATION)) {
                    this.resourcePath = ['/dashboard/account'];
                }
            } else {
                this.resourcePath = ['/dashboard/location_details', this.auditEvent.locationId];
            }
        } else if (this.auditEvent.customerId) {
            if (this.authenticationService.isCustomerUser()) {
                if (this.authenticationService.checkPermission(Permissions.WRITE_CUSTOMER)) {
                    this.resourcePath = ['/dashboard/account'];
                }
            } else {
                this.resourcePath = ['/dashboard/customer_details', this.auditEvent.customerId];
            }
        } else if (this.auditEvent.partnerId) {
            if (this.authenticationService.isPartnerUser()) {
                if (this.authenticationService.checkPermission(Permissions.WRITE_PARTNER)) {
                    this.resourcePath = ['/dashboard/account'];
                }
            } else {
                this.resourcePath = ['/dashboard/partner_details', this.auditEvent.partnerId];
            }
        } else if (this.auditEvent.organizationId) {
            if (this.authenticationService.getUser().organizationId == this.auditEvent.organizationId) {
                this.resourcePath = ['/dashboard/organization'];
            } else {
                this.resourcePath = ['/dashboard/organization', this.auditEvent.organizationId];
            }
        }
    }

    goToResource() {
        this.navigateToResource.emit();
        this.navigationService.navigateTo(this.resourcePath);
    }

    private computeRollbackableInfo(): void {
        try {
            const messageObject = JSON.parse(this.auditEvent.message);
            this.rollbackMetricValueInfo = {
                rollbackable: this.auditEvent.thingId && messageObject && messageObject.rollbackable && messageObject.oldValue && messageObject.timestamp && messageObject.metricName,
                metricName: messageObject.metricName,
                oldValue: messageObject.oldValue,
                timestamp: messageObject.timestamp
            }
        } catch {
            this.rollbackMetricValueInfo = null;
        }
    }

    confirmRollbackMetricValue(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.minWidth = '25%';
        dialogConfig.panelClass = "rollback-audit-event";
        dialogConfig.autoFocus = false;
        dialogConfig.data = {
            title: "rollbackAuditEventMessage",
            message: "rollbackAuditEventInfoMessage"
        }
        this.dialog.open(ConfirmDialog, dialogConfig).afterClosed().pipe(take(1)).subscribe(result => {
            if (result) {
                this.dataService.rollbackMetric(this.auditEvent.thingId, this.rollbackMetricValueInfo.metricName, this.rollbackMetricValueInfo.oldValue, this.rollbackMetricValueInfo.timestamp).then(() => {
                    this.showSnackbar('rollbackExecutedMessage');
                    this.error = null;
                    this.refreshAction.emit();
                }).catch(err => this.error = ErrorUtility.getMessage(err, ErrorMessages.SAVE_DATA_ERROR));
            }
        });
    }

    private showSnackbar(text: string): void {
        this.labelService.getCustomLabel(text)
            .then(message => {
                this.snackBar.open(this.localizationPipe.transform(message), '', {
                    duration: 5000,
                    panelClass: 'notification-info'
                });
            });
    }
}